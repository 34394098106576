const getHost = () => {
  const host =
    (process.env.NODE_ENV === 'production' ? 'wss://' : 'ws://') +
    process.env.REACT_APP_ENV
  return host
}

const getPort = () => {
  const port = process.env.REACT_APP_ENV === 'localhost' ? ':8000' : ''
  return port
}

const getWsURLPrefix = () => {
  const host = getHost()
  const port = getPort()
  return host + port + '/ws/'
}

export default getWsURLPrefix
